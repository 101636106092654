import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = {
  type: "button",
  class: "btn btn-flex btn-light btn-active-primary fw-bolder",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-start",
  "data-kt-menu-flip": "top-end"
}
const _hoisted_5 = { class: "svg-icon svg-icon-5 svg-icon-gray-500 me-1" }
const _hoisted_6 = { class: "card-toolbar" }
const _hoisted_7 = {
  type: "button",
  class: "btn btn-light-primary me-3",
  "data-bs-toggle": "modal",
  "data-bs-target": "#kt_customers_export_modal"
}
const _hoisted_8 = { class: "svg-icon svg-icon-2" }
const _hoisted_9 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_10 = { class: "svg-icon svg-icon-2" }
const _hoisted_11 = { class: "card-body pt-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_CustomerCreditFilter = _resolveComponent("CustomerCreditFilter")!
  const _component_vue3_json_excel = _resolveComponent("vue3-json-excel")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_ctx.can('read', 'finance'))
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("button", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, [
                _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen031.svg" })
              ]),
              _createTextVNode(" " + _toDisplayString(_ctx.translate('filter')), 1)
            ]),
            _createVNode(_component_CustomerCreditFilter, {
              onFilterSelected: _ctx.searchCustomerCredit,
              onReset: _ctx.onReset
            }, null, 8, ["onFilterSelected", "onReset"])
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createVNode(_component_vue3_json_excel, {
              "json-data": _ctx.excelData,
              fields: _ctx.excelHeader,
              worksheet: "customer WorkSheet",
              name: "customer-credit.xls"
            }, {
              default: _withCtx(() => [
                _createElementVNode("button", _hoisted_7, [
                  _createElementVNode("span", _hoisted_8, [
                    _createVNode(_component_inline_svg, { src: "media/icons/duotune/arrows/arr078.svg" })
                  ]),
                  _createTextVNode(" " + _toDisplayString(_ctx.translate('Export Customer Credit')), 1)
                ])
              ]),
              _: 1
            }, 8, ["json-data", "fields"]),
            _createElementVNode("div", _hoisted_9, [
              (_ctx.can('create', 'finance'))
                ? (_openBlock(), _createElementBlock("button", {
                    key: 0,
                    type: "button",
                    class: "btn btn-primary",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('finance-customer-credit-creating')))
                  }, [
                    _createElementVNode("span", _hoisted_10, [
                      _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Plus.svg" })
                    ]),
                    _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewCustomerCredit')), 1)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_Datatable, {
            "table-data": _ctx.tableData,
            "table-header": _ctx.tableHeader,
            loading: _ctx.loading,
            "rows-per-page": 10,
            total: _ctx.totalItems,
            "enable-items-per-page-dropdown": false,
            onCurrentChange: _ctx.pageChanged
          }, {
            "cell-date": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.date), 1)
            ]),
            "cell-customerName": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.customerName), 1)
            ]),
            "cell-serviceType": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.serviceType), 1)
            ]),
            "cell-customerPhoneNumber": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.customerPhoneNumber), 1)
            ]),
            "cell-totalPrice": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.totalPrice), 1)
            ]),
            "cell-amount": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.amount), 1)
            ]),
            "cell-discount": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.discount) + " % ", 1)
            ]),
            "cell-priceBeforeDiscount": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.priceBeforeDiscount), 1)
            ]),
            "cell-status": _withCtx(({ row: customerCredit }) => [
              _createTextVNode(_toDisplayString(customerCredit.status ? 'Paid' : 'Not Paid'), 1)
            ]),
            _: 1
          }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
        ])
      ]))
    : _createCommentVNode("", true)
}